.connect-button {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(39, 39, 39, 0.355) 100%);
    border: 0.5px solid #FFFFFF;
    border-radius: 17px;   
    padding: 8px 20px;
    cursor: pointer;

    display: flex;
    align-items: center;

    .connect-button-progress {
        margin-left: 10px;
        margin-top: 4px;
    }

    p {
        font-family: "Be Vietnam";
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        font-feature-settings: "ss01";
    }
}
