.sb-menu-root {
    .sb-menu-btn {
        padding: 8px 20px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.355) 100%);
        border: 0.5px solid #ffffff;
        border-radius: 1px;
        // box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
        cursor: pointer;
        margin-right: 20px;

        p {
            font-family: "Be Vietnam";
            font-weight: 500;
            font-size: 16px;
            color: #ffffff;
        }
    }
}

.sb-menu-popper {
    margin-top: 10px;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.355) 100%);
        backdrop-filter: blur(100px);
        border: 0.5px solid #ffffff;
    }

    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: rgba(0, 1, 40, 0.9);
    }

    border-radius: 0px;
    overflow: hidden;
    z-index: 10;

    .tooltip {
        display: flex;
        flex-direction: column;

        .tooltip-item {
            text-decoration: none;
            padding: 13px 20px;
            cursor: pointer;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }

            p {
                font-family: Be Vietnam Medium;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
                text-align: center;
            }
        }

        .add-tokens {
            display: flex;
            flex-direction: column;

            .add-tokens-title {
                font-family: Rajdhani;
                font-weight: 400;
                font-size: 12px;
                color: #ffffff;
                text-align: center;

                margin: 5px 0px;
            }
        }

        .divider {
            height: 1px;
            background: #ffffff;
            border: none;
        }
    }
}
