.rebase-timer {
    position: relative;

    p {
        font-family: Rajdhani;
        font-weight: 400;
        color: #ffffff;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
    }
}
