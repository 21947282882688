@font-face {
    font-family: "Be Vietnam SemiBold";
    src: local("Be Vietnam SemiBold"), url("../fonts/ABCDiatype-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Be Vietnam";
    src: local("Be Vietnam"), url("../fonts/ABCDiatype-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Be Vietnam Bold";
    src: local("Be Vietnam Bold"), url("../fonts/ABCDiatype-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Be Vietnam Medium";
    src: local("Be Vietnam Medium"), url("../fonts/ABCDiatype-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Be Vietnam Light";
    src: local("Be Vietnam Light"), url("../fonts/ABCDiatype-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Rajdhani";
    src: local("Rajdhani"), url("../fonts/Rajdhani-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Rajdhani Bold";
    src: local("Rajdhani Bold"), url("../fonts/Rajdhani-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Courier";
    src: local("Courier"), url("../fonts/CourierPrime-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Nib";
    src: local("Nib"), url("../fonts/NibPro-Light.ttf") format("truetype");
}
