.dapp-topbar {
    width: 96%;
    align-items: center;
    margin: auto;

    .dapp-topbar-slider-btn {
        background: rgb(255, 255, 255);
        box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
        border-radius: 1px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 16px;
    }

    .dapp-topbar-btns-wrap {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
}

.dapp-information-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #5a5a5a; //linear-gradient(180deg, #000000 0%, #5a5a5a 100%);;
}

.dapp-information-text {
    font-family: "Permanent Marker";
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    font-feature-settings: "ss01";
    text-align: center;
    padding: 2px;

    a {
        color: #d0c4ff;
    }
}

@media screen and (max-width: 960px) {
    .dapp-information-box {
        display: none;
    }
}

@media screen and (min-width: 960px) {
    .dapp-topbar {
        .dapp-topbar-slider-btn {
            display: none;
        }
    }
}
