.not-found-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    p {
        font-family: Be Vietnam SemiBold;
        font-weight: 600;
        font-size: 30px;
        color: #ffffff;
    }
}
