.choose-bond-view {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    width: 89%;
    max-width: 833px;
    margin: auto;
    position: relative;

    .choose-bond-view-card {
        border-radius: 4px;
        padding: 20px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        border: 0.994648px solid rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(30px);
        position: relative;
        z-index: 2;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background-color: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(30px);
        }

        /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background-color: rgba(255, 255, 255, 0.1);
        }

        .choose-bond-view-card-header {
            .choose-bond-view-card-title {
                font-family: Be Vietnam Medium;
                font-weight: 500;
                font-size: 24px;
                color: #ffffff;
                margin-bottom: 4px;
                font-feature-settings: "ss01";
                padding: 8px;
            }
        }

        .choose-bond-view-card-metrics {
            margin: 30px 0;

            .choose-bond-view-card-metrics-title {
                font-family: Be Vietnam;
                font-weight: 400;
                font-size: 16px;
                color: #919fa6;
                padding-bottom: 6px;
                font-feature-settings: "ss01";
            }

            .choose-bond-view-card-metrics-value {
                font-family: Be Vietnam SemiBold;
                font-weight: 500;
                font-size: 24px;
                color: #ffffff;
                font-feature-settings: "ss09", "ss11";
            }
        }

        .choose-bond-view-card-table {
            .choose-bond-view-card-table-title {
                font-family: Rajdhani;
                font-weight: 400;
                color: #ffffff;
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 1px;
            }
        }
    }

    .MuiTableRow-root td:first-child {
        height: 100%;
    }

    .choose-bond-view-card-container {
        margin-top: 16px;

        .bond-data-card {
            margin: auto !important;
            background: #432478;
            background: linear-gradient(0deg, #2e458b 0%, #37337e 35%, #432478 100%);
            backdrop-filter: blur(100px);
            border-radius: 10px;
            padding: 20px;
            width: 100%;

            .bond-pair {
                display: flex !important;
                align-items: center;
                justify-content: left;
                margin-bottom: 15px;
                .MuiBox-root {
                    margin-right: 10px;
                }
                .bond-name {
                    svg {
                        margin-left: 5px;
                        vertical-align: middle;
                        font-size: 15px;
                    }
                }
            }
            .data-row {
                margin-bottom: 13px;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                margin: 12px 0;

                .bond-price {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 5px;
                    }
                }
            }
            .bond-link {
                margin-top: 10px;
                text-align: center;
                a {
                    width: 96%;
                }
            }
        }
    }

    .currency-icon {
        display: inline-block;
        min-width: 15px;
    }

    .bond-name-title {
        font-family: Be Vietnam Medium;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        font-feature-settings: "ss09", "ss11";
    }

    .bond-table-btn {
        background: #4144e8;
        border: 1px solid #2e1d5c;
        border-radius: 4px;
        padding: 8px 35.5px;
        text-align: center;

        &:hover {
            border: 1px solid rgba(255, 255, 255, 0.6);
        }

        p {
            font-family: Be Vietnam Medium;
            font-weight: 500;
            font-size: 16px;
            color: #ffffff;
        }
    }

    .bond-soldout {
        opacity: 0.6;

        .bond-table-btn {
            background: #919fa6;
            border: 1px solid #30363a;

            p {
                color: #30363a;
            }
        }
    }

    .MuiTable-root {
        margin: 10px 0px;
        height: 72px;
        border-spacing: 0 0px;
        border-collapse: separate;
    }

    .MuiTableRow-head {
        .MuiTableCell-alignLeft {
            padding-left: 20px;
        }
    }

    .MuiTableCell-root {
        p {
            margin: 0 !important;
            display: flex !important;
            align-items: center;
            justify-content: space-evenly;
            white-space: nowrap;
            img {
                margin-right: 3px;
            }
        }
        &.MuiTableCell-alignLeft {
            display: flex;
        }

        border-bottom: none;
    }

    .MuiTableBody-root .MuiTableRow-root .MuiTableCell-alignLeft {
        justify-content: space-between;
        div.ohm-pairs {
            width: 33%;
            vertical-align: middle !important;
            align-items: center !important;
            justify-content: center !important;
            margin-bottom: 0 !important;
            .ohm-pairs div.bond-name p,
            .ohm-pairs svg {
                align-self: center !important;
                line-height: 100% !important;
                font-weight: bold !important;
                margin-bottom: 0em !important;
                padding: 0 !important;
                font-family: inherit;
                vertical-align: text-top !important;
            }
            .ohm-logo-bg {
                margin-left: 0;
                border-radius: 20px;
                height: 40px;
                width: 40px;
            }
        }
        div.bond-name {
            width: 60%;
            margin-left: 8px !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            align-items: flex-start;
            p {
                margin-bottom: unset !important;
                line-height: 140% !important;
            }
            svg {
                margin-left: 3px !important;
                font-size: 1.2em !important;
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .choose-bond-view {
        width: 100%;
    }
}
