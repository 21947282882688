.bond-view {
    position: absolute !important;
    width: 100%;
    height: 100%;
    z-index: 69;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .MuiBackdrop-root {
            background: rgba(100, 100, 100, 0.1) !important;
            backdrop-filter: blur(33px) !important;
            -webkit-backdrop-filter: blur(33px) !important;
        }
    }

    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        .MuiBackdrop-root {
            background: rgba(0, 0, 0, 0.8);
        }
    }

    .bond-card {
        max-width: 803px;
        min-height: 605px;
        background: #432478;
        background: linear-gradient(0deg, #2e458b 0%, #37337e 35%, #432478 100%);
        border: 1px solid #30363a;
        backdrop-filter: blur(100px);
        border-radius: 4px;
        padding: 20px;
        width: 100%;
    }

    .bond-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bond-header-logo {
            justify-content: center;
            align-items: center;
            text-align: center;
            display: flex;
            flex-direction: row;

            .ohm-logo-bg {
                height: 40px !important;
                width: 40px !important;
                padding: 8px !important;
                margin-left: 0;
            }

            .bond-header-name {
                text-align: center;
                margin-left: 10px;

                font-family: Be Vietnam Medium;
                font-weight: 500;
                font-size: 18px;
                color: #ffffff;
            }
        }
    }

    .bond-data {
        margin: 10px 0 0 0;
    }

    .bond-price-data {
        .bond-price-data-title {
            font-family: Be Vietnam;
            font-weight: 400;
            font-size: 16px;
            color: #919fa6;
            padding-bottom: 6px;
            font-feature-settings: "ss01";
        }

        .bond-price-data-value {
            font-family: Be Vietnam SemiBold;
            font-weight: 500;
            font-size: 24px;
            color: #ffffff;
            font-feature-settings: "ss09", "ss11";
        }
    }

    .bond-price-data-row {
        display: flex;
        justify-content: space-around;
        text-align: center;
        margin: 23px;

        h4 {
            margin-bottom: 0;
        }

        h3.price {
            font-weight: 600 !important;
        }
    }

    .ohm-input {
        min-width: 50%;
        max-width: 408px;
        margin: 5px;

        .avax-checkbox {
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
                height: 15px;
                width: 15px;
            }

            p {
                font-family: Be Vietnam Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #ffffff;
                margin-left: 5px;
            }
        }
    }

    .transaction-button {
        min-width: 185px !important;
        max-width: 408px;
        width: 100%;
        margin: 5px !important;
    }

    .bond-one-table {
        display: flex;
        align-items: center;
        justify-content: center;

        .bond-one-table-btn {
            margin: 0 20px;
            cursor: pointer;
            transition: all 0.4s ease;
            padding: 6px;

            p {
                font-family: Rajdhani;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                text-transform: uppercase;
                color: #fff;
            }
        }

        .bond-one-table-btn.active {
            border-bottom: 1px solid #ffffff;

            p {
                color: #ffffff;
            }
        }
    }

    .bond-input-wrap {
        min-width: 50%;
        max-width: 408px;
    }

    .bond-input {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        outline: none;
        margin-bottom: 20px;

        .MuiOutlinedInput-input {
            font-family: Be Vietnam Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.9);
            padding: 14px 20px;
            font-feature-settings: "ss09", "ss11";
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: rgba(255, 255, 255, 0.2) !important;
        }

        .stake-input-btn {
            cursor: pointer;

            p {
                font-family: Rajdhani;
                font-weight: 400;
                color: #ffffff;
                text-transform: uppercase;
                font-size: 16px;
                letter-spacing: 1px;
            }
        }
    }

    .bond-approve-btn {
        background: #4144e8;
        border-radius: 4px;
        padding: 14px 0px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ffffff42;

        p {
            font-family: Be Vietnam Medium;
            font-weight: 500;
            font-size: 16px;
            color: #ffffff;
        }
    }

    .help-text {
        margin-top: 20px;

        .help-text-desc {
            font-family: Rajdhani;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
        }
    }

    .bond-balance-title {
        font-family: Be Vietnam;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
        font-feature-settings: "ss09", "ss11";
    }
}

.mobile #bond-view {
    .ohm-card {
        .card-content {
            justify-content: space-evenly !important;

            .bond-toggle-row {
                justify-content: space-evenly !important;
            }

            .input-row {
                flex-direction: column;
                width: 100%;
                justify-content: center;
                align-items: center;
                align-content: center;

                .input-group.ohm-input-group {
                    margin: 10px !important;
                    width: 75%;
                    height: 50px;
                }

                div.stake-button {
                    width: 75%;
                    margin-left: 0;
                    margin-top: 15px;
                    height: 50px;
                    font-size: 1.3em !important;
                }
            }
        }
    }
}

@media screen and (max-width: 417px) {
    .bond-price-data-title {
        font-size: 17px !important;
    }

    .bond-price-data-value {
        font-size: 19px !important;
    }
}
